@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~leaflet/dist/leaflet.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}